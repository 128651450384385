import React, { useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
//image
import loader from "../../assets/images/page-img/page-load-loader.gif";
import {
  deleteImage,
  getUser,
  updateUser,
  uploadImages,
} from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoader, setUser } from "../../store/users";
import { dash, personCircle, plus, reminder } from "../../consts/SvgIcon";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import GradientButton from "../../components/Buttons/GradientButton";
import { toast } from "react-toastify";
import { pushEvent } from "../../utils";
import { Image, UsersModule } from "../../types/redux";

const UserDataEdit = () => {
  const notify = (message) => toast.warning(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: UsersModule) => state.usersModule).user;
  const [userData, setUserData] = useState({
    ...user,
    country:
      user?.country?.charAt(0).toUpperCase() + user?.country?.slice(1) || "",
  });
  const showLoader = useSelector(
    (state: UsersModule) => state.usersModule
  ).showLoader;
  const mainImage = useSelector(
    (state: UsersModule) => state.usersModule
  ).mainImage;
  const errorMessage = useSelector(
    (state: UsersModule) => state.usersModule
  ).errorMessage;
  const [modalProps, setModalProps] = useState(false);
  const [imageId, setImageId] = useState<number | string>("");
  const [country, setCountry] = useState(userData?.country);
  const [city, setCity] = useState(userData?.country);
  const [main, setMain] = useState({ ...mainImage });
  const [imagesForDelete, setImagesForDelete] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sortImages = (images) => {
    const imgs = [];
    images?.forEach((img) => {
      if (img.isMain !== true) imgs.push(img);
    });
    const amount = 3 - imgs.length;
    for (let i = 0; i <= amount; i++) {
      imgs.push(null);
    }
    return imgs;
  };
  const [images, setImages] = useState(sortImages([...userData.images]));

  const handleEdit = (event) => {
    const target = event.target;
    const userDataClone = { ...userData, country: country, city: city };
    userDataClone[target.id] = target.value;
    setUserData(userDataClone);
  };
  const handleMainImageChange = (e) => {
    const targetFiles = e.target.files;
    const newMain = Object.keys(targetFiles).map(() => ({
      file: targetFiles[0],
      url: targetFiles[0] && URL.createObjectURL(targetFiles[0]),
      ...mainImage,
    }));
    setMain(newMain[0]);
    if (e.target.files?.length > 1) {
      handleImageChange(targetFiles, true);
    }
  };
  const handleImageChange = (e, setMainImg?) => {
    const targetFiles = e;
    let newImages = [
      ...images.filter((el) => el?.url),
      ...Object.keys(targetFiles)
        .filter((i, idx) => (setMainImg ? idx : 1))
        .map((key) => ({
          file: targetFiles[key],
          url: targetFiles[key] && URL.createObjectURL(targetFiles[key]),
        })),
    ];
    if (newImages.length < 4) {
      Array.from(Array(4 - newImages.length).keys()).forEach(() => {
        newImages.push(null);
      });
    }
    if (newImages.length > 4) {
      newImages.splice(4, newImages.length - 3);
    }
    setImages(newImages);
  };
  const confirmDelete = () => {
    if (imageId === "main") {
      setMain(null);
      setModalProps(false);
    } else {
      const newImages = [...images];
      if (main?.url !== newImages[imageId])
        setImagesForDelete([...imagesForDelete, newImages[imageId]]);
      newImages[imageId] = null;
      setImages(newImages);
      setModalProps(false);
    }
  };
  const ModalRender = () => {
    return (
      <Modal
        backdropClassName={"bg-transparent"}
        centered
        backdrop={true}
        show={modalProps}
        onHide={() => setModalProps(false)}
        animation={true}
      >
        <div>
          <div className="modal-header border-0">
            <h5 className="modal-title fw-bold" id="exampleModalLongTitle">
              Confirmation
            </h5>
          </div>
          <div className="modal-body">
            Are you sure that you want to delete the photo?
          </div>
          <div className="modal-footer border-0">
            <div
              className="dashboard-card-buttons custom-gradient me-2"
              onClick={() => confirmDelete()}
              style={{ width: "100px", height: "30px" }}
            >
              <div className="dashboard-card-buttons-text">
                <span>Yes</span>
              </div>
            </div>
            <button
              className="custom-btn text-black mb-1"
              onClick={() => setModalProps(false)}
              style={{ height: "30px", width: "100px" }}
            >
              <div className="dashboard-card-buttons-text fw-bold">No</div>
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonDisabled(true);
    dispatch(setShowLoader(true));
    const { firstName, lastName, phoneNumber, description, gender, birthday } =
      event.target.elements;
    const filteredImg = [
      main.file,
      ...images?.filter((img) => img?.file).map((el) => el?.file),
    ];
    if (imagesForDelete?.length) {
      imagesForDelete.forEach((el) => {
        if (el?.id) {
          deleteImage(el?.id);
        }
      });
    }
    if (filteredImg?.length > 2) {
      uploadImages(filteredImg).then((r) => {
        if (r?.data?.count)
          updateUser({
            firstName: firstName.value,
            lastName: lastName.value,
            description: description.value,
            phoneNumber: phoneNumber.value,
            gender: gender.value.toUpperCase(),
            birthday: birthday.value,
            city: city,
            country: country,
          }).then(() => {
            pushEvent("complete_personal_information", "user_data_edit");
            getUser().then((response) => {
              dispatch(setUser(response.data));
              setButtonDisabled(false);
              navigate("/pricing-page");
            });
          });
      });
    } else {
      setButtonDisabled(false);
      toast.dismiss();
      toast.clearWaitingQueue();
      notify("Please add at least 3 images, you can change them later.");
      dispatch(setShowLoader(false));
    }
  };
  return (
    <>
      <Container>
        <Col xs={12}>
          <Card className="p-4 pt-3 pb-3 mt-3 shadow-card">
            <Card.Header className="d-flex justify-content-between border-0">
              <div className="header-title">
                <h3 className="card-title fw-bold">Personal information</h3>
              </div>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit} id="form">
                <Col md="12" className="d-flex justify-content-start flex-wrap">
                  <div
                    className="profile-img-edit"
                    style={{ margin: "0 20px 10px 0" }}
                  >
                    {main?.url ? (
                      <div>
                        <div
                          className="profile-pic"
                          style={{
                            backgroundImage: `url(${main.url})`,
                          }}
                        />
                        <div
                          className="d-flex justify-content-center align-items-center p-image-delete"
                          onClick={() => {
                            setModalProps(true);
                            setImageId("main");
                          }}
                        >
                          {dash()}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="profile-default-pic">
                          {personCircle()}
                        </div>
                        <div className="d-flex justify-content-center align-items-center p-image-add">
                          {plus()}
                          <input
                            className="file-upload"
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              opacity: 0,
                              display: "block",
                            }}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            multiple
                            name="image"
                            id="image"
                            onChange={handleMainImageChange}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-start flex-wrap">
                    {images.map((img, index) => {
                      if (img !== null)
                        return (
                          <div
                            key={index}
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                          >
                            <div
                              className="profile-pic"
                              style={{
                                backgroundImage: `url(${img.url})`,
                              }}
                            />
                            <div
                              className="d-flex justify-content-center align-items-center p-image-delete"
                              onClick={() => {
                                setModalProps(true);
                                setImageId(index);
                              }}
                            >
                              {dash()}
                            </div>
                          </div>
                        );
                      else
                        return (
                          <div
                            style={{
                              margin: "0 20px 10px 0",
                              position: "relative",
                            }}
                            key={index}
                          >
                            <div className="profile-default-pic">
                              {personCircle()}
                            </div>
                            <div className="d-flex justify-content-center align-items-center p-image-add">
                              {plus()}
                              <input
                                className="file-upload"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  position: "absolute",
                                  opacity: 0,
                                  display: "block",
                                }}
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                multiple
                                name="image"
                                id="image"
                                onChange={(e) =>
                                  handleImageChange(e.target.files)
                                }
                              />
                            </div>
                          </div>
                        );
                    })}
                  </div>
                  {ModalRender()}
                </Col>
                <p className="text-danger">{errorMessage}</p>
                <div className="pro-tip-container">
                  <div className="text-dark d-flex align-items-center gap-1 fw-bold">
                    {reminder()} Pro Tip
                  </div>
                  <span className="text-dark">
                    Including 3+ high-quality photos on your profile page will
                    enhance your journey towards achieving your dream.
                  </span>
                </div>
                <Row className="align-items-center">
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="firstName"
                      className="form-label fw-bold"
                    >
                      First name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control border border-light"
                      id="firstName"
                      placeholder="First Name"
                      required
                      value={userData.firstName || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="lastName"
                      className="form-label fw-bold"
                    >
                      Last name*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control border border-light"
                      id="lastName"
                      required
                      placeholder="Last Name"
                      value={userData?.lastName || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="birthday"
                      className="form-label fw-bold"
                    >
                      Birth Date*
                    </Form.Label>
                    <Form.Control
                      type="date"
                      required
                      className="form-control border border-light"
                      id="birthday"
                      placeholder="Birth Date"
                      value={userData?.birthday || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </Form.Group>{" "}
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label htmlFor="Gender" className="form-label fw-bold">
                      Gender*
                    </Form.Label>
                    <Form.Select
                      className="form-control border border-light"
                      id="gender"
                      required
                      value={userData?.gender || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    >
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                    </Form.Select>
                  </Form.Group>{" "}
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="Email Address"
                      className="form-label fw-bold"
                    >
                      Email Address*
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="form-control border border-light"
                      id="email-adress"
                      placeholder="Email Address"
                      required
                      value={userData.email || ""}
                      disabled={true}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="phoneNumber"
                      className="form-label fw-bold"
                    >
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      className="form-control border border-light"
                      id="phoneNumber"
                      placeholder="Phone Number"
                      value={userData?.phoneNumber || ""}
                      style={{
                        backgroundColor: "#f8f9fa",
                        height: "35px",
                      }}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label
                      htmlFor="firstName"
                      className="form-label fw-bold"
                    >
                      Country*
                    </Form.Label>
                    <CountryDropdown
                      classes="form-control border border-light form-select region-dropdown"
                      value={country || ""}
                      onChange={(country) => setCountry(country)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-6">
                    <Form.Label htmlFor="City" className="form-label fw-bold">
                      City*
                    </Form.Label>
                    <RegionDropdown
                      classes="form-control border border-light form-select region-dropdown"
                      country={country}
                      value={city || ""}
                      onChange={(city) => setCity(city)}
                    />
                  </Form.Group>
                  <Form.Group className="form-group col-sm-12">
                    <Form.Label className="form-label fw-bold">
                      Description:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      className="form-control border border-light"
                      rows={5}
                      style={{
                        resize: "none",
                        backgroundColor: "#f8f9fa",
                      }}
                      id="description"
                      placeholder="Description"
                      value={userData?.description || ""}
                      onChange={handleEdit}
                    />
                  </Form.Group>
                </Row>
                <div className="d-flex justify-content-end">
                  {showLoader && (
                    <img
                      src={loader}
                      alt="loader"
                      style={{ height: "39.2px" }}
                    />
                  )}
                  <button
                    className="border-0 bg-white p-0 "
                    form="form"
                    type="submit"
                    disabled={buttonDisabled}
                  >
                    <GradientButton text="Submit" minWidth="137px" />
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default UserDataEdit;
